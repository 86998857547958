import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Button from "../components/buttons/button"
import ShopNav from "../components/shop/shopnav"
import ShopItemsPremade from "../components/shop/shopitemspremade"
import ShopItemsByobox from "../components/shop/shopitemsbyobox"


const Shop = (props) => {
  // if(props.location.state[0]) console.log(props.location.state[0]);

  // useEffect(() => {

  //     if (props.location.state[0]){
  //       window.Snipcart.api.theme.cart.open();
  //     }

  // }, [])

  return (
    <Layout>
      <SEO title="Shop" />
      <section className="u-standard-section-margins shop">
      <div className="u-display-none-tablet">
        <ShopNav />
      </div>

        <div className="shop__productsbox">
          <ShopItemsPremade />

          <ShopItemsByobox />

          {/* ************ CUSTOMIZE ************ */}
          <h1 id="personalized" className="u-margin-top-huge shop__header">
            Get personalized recommendations
          </h1>
          <p className="shop__subtitle">
            Try our custom stress assesment quiz to get personalized supplement
            recommendations
          </p>

          <div className="u-margin-top-medium shop__buttonbox">
            <Button text="Take quiz" type="primary" link="/quiz" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Shop
