import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ShopNav = () => {
    return (
        <div className="shop__nav">
          <div className="shop__nav__fixed">
            <h2 className="shop__nav__fixed__header">Categories</h2>
            <hr className="shop__nav__fixed__line" />
            <div className="u-margin-top-small shop__nav__fixed__item">
              <AnchorLink 
                className="shop__nav__link" 
                to="/shop#recommended" 
                title="Recommended boxes"
              >
                Recommended Boxes
              </AnchorLink>
            </div>
            <div className="shop__nav__fixed__item">
              <AnchorLink className="shop__nav__link" to="/shop#byobox" title="Build your own box">
                Build Your Own Box

              </AnchorLink>
            </div>
            <div className="shop__nav__fixed__item">
            <AnchorLink className="shop__nav__link" to="/shop#personalized" title="Personalized Supplements">
              Personalized Supplements
            </AnchorLink>
            </div>
          </div>
        </div>
    )
}

export default ShopNav;