import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ShopCard from "./shopcard"

const ShopItemsByobox = () => {
  return (
    <StaticQuery
      query={graphql`
        query byoboxquery {
          allDatoCmsProduct {
            edges {
              node {
                id
                name
                price
                description
                slug
                photos {
                  alt
                  originalId
                  fluid {
                    ...GatsbyDatoCmsFluid
                  }
                }
                supplementLink {
                  description
                }
              }
            }
          }
        }
      `}
      render={data => {
        const productData = data.allDatoCmsProduct.edges
        return (
          <>
            <h1 id="byobox" className="u-margin-top-huge shop__header">
              Build your own box
            </h1>
            <p className="shop__subtitle">
              Our curated selection of the most effective, all-natural
              suppelements for stress relief in convenient 1 week dosage packs
            </p>

            <div className="u-margin-top-medium shop__container">
              {productData.map(({ node: product }) => {
                return (
                  <ShopCard
                    key={product.id}
                    id={product.id}
                    photos={product.photos}
                    name={product.name}
                    price={product.price}
                    description={product.supplementLink[0].description}
                    link={`/shop/${product.slug}`}
                  />
                )
              })}
            </div>
          </>
        )
      }}
    />
  )
}

export default ShopItemsByobox
